import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from '../components/Markdown';
import Typography from '../components/Typography';
import AppAppBar from './AppAppBar';
import AppFooter from './AppFooter';
import withRoot from '../withRoot';

const terms = `
## Terms of Use Agreement for the EasyTXF website
Welcome to our website, which provides a platform for converting financial information into other file formats. By using this website, you agree to be bound by the following terms of use agreement. If you do not agree to these terms, please do not use our website.

1. Acceptance of Terms
By accessing or using our website, you agree to be bound by these Terms of Use, as well as any other policies, guidelines or rules posted on the website. These Terms of Use apply to all visitors, users and others who access or use the website.

2. Description of Service
Our website allows users to upload financial information in one file format and convert it to another file format. The financial information uploaded to our website is only stored temporarily in order to support the functionality of the application. No personally identifiable information is collected or stored.

3. Use of Service
Our website is intended for personal, non-commercial use only. You agree to use our website only for lawful purposes and in accordance with these Terms of Use. You may not use our website:

In any way that violates any applicable federal, state, local or international law or regulation.
For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.
To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter" or "spam" or any other similar solicitation.
To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).
To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the website, or which, as determined by us, may harm the Company or users of the website or expose them to liability.
4. Intellectual Property Rights
All content and materials available on our website, including but not limited to text, graphics, logos, button icons, images, audio clips, data compilations, and software, are the property of the Company or its licensors and are protected by United States and international copyright laws.

5. Disclaimer of Warranties
The use of our website is at your own risk. The website is provided on an "as is" and "as available" basis. The Company does not warrant that the website will be uninterrupted or error-free, nor does it make any warranty as to the results that may be obtained from the use of the website, or as to the accuracy, reliability, or currency of any information, content, service, or merchandise provided through the website.

6. Limitation of Liability
In no event shall the Company be liable for any direct, indirect, incidental, special or consequential damages arising out of or in any way connected with the use of our website, whether based on contract, tort, strict liability or otherwise, even if advised of the possibility of such damages.

7. Indemnification
You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the website.

8. Changes to Terms of Use
The Company reserves the right, in its sole discretion, to change these Terms of Use at any time by posting`

function Terms() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Terms of Service
          </Typography>
          <Markdown>{terms}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Terms);
