import CssBaseline from "@mui/material/CssBaseline";
import Home from "./Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "./modules/views/Privacy";
import Terms from "./modules/views/Terms";
import FileUpload from "./modules/views/FileUpload";
import Donations from "./modules/views/Donations";
import FieldMapping from "./modules/views/FieldMapping";
import Download from "./modules/views/Download";

function App() {
  return (
    <div>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/mapping" element={<FieldMapping />} />
          <Route path="/download" element={<Download />} />
          <Route path="/get-started" element={<FileUpload />} />
          <Route path="/donate" element={<Donations/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
