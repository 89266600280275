import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      Copyright&nbsp;
      <Link color="inherit" href="https://rodwhisnant.com">
        Rod Whisnant
      </Link>{" "}
      {new Date().getFullYear()}
      &nbsp; - All Rights Reserved
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "warning.main",
  mr: 1,
  "&:hover": {
    bgcolor: "warning.dark",
  },
};

const LANGUAGES = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "fr-FR",
    name: "Français",
  },
];

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.light" }}
    >
      <Grid container sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              component="a"
              href="https://twitter.com/rodwhisnant"
              sx={iconStyle}
            >
              <img src="/appFooterTwitter.png" alt="Twitter" />
            </Box>

            <Box>
              <Copyright />
              <br></br>
              <Typography variant="caption">
                {"Icons made by "}
                <Link
                  href="https://www.freepik.com"
                  rel="sponsored"
                  title="Freepik"
                >
                  Freepik
                </Link>
                {" from "}
                <Link
                  href="https://www.flaticon.com"
                  rel="sponsored"
                  title="Flaticon"
                >
                  www.flaticon.com
                </Link>
                {" is licensed by "}
                <Link
                  href="https://creativecommons.org/licenses/by/3.0/"
                  title="Creative Commons BY 3.0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC 3.0 BY
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              p: 1,
            }}
          >
            <Box>
              <Typography variant="h6" marked="left" gutterBottom>
                Legal
              </Typography>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Link href="/terms">Terms</Link>&nbsp;&nbsp;
              <Link href="/privacy">Privacy</Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Typography>
  );
}
