import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import AppAppBar from "./AppAppBar";
import withRoot from "../withRoot";
import AppFooter from "./AppFooter";

function Donations() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
        <Container
          sx={{ mt: 10, mb: 30, display: "flex", position: "relative" }}
        >
          <Box
            sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
          />

          <Container component="section" sx={{ mt: 8, mb: 4 }}>
            <Typography
              variant="h3"
              align="center"
              component="h2"
              marked="center"
            >
              Your donation helps
            </Typography>
            <Typography>
              I hope that you have found this service to be of value to you, and
              I would greatly appreciate any donation that you can give to help
              me continue to provide the best possible service.
            </Typography>
            <br></br>
            <Typography>
              As you may know, maintaining and improving this website requires a
              significant amount of resources, including infrastructure costs,
              software development, and maintence. Your donation will directly
              support the continued operation and growth of this service,
              allowing me to continue to provide you with a reliable experience.
            </Typography>

            <Box sx={{ textAlign: "center", width: "100%", mt: 3 }}>
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="4BNC8E6CFQCFJ"
                />
                <input
                  type="image"
                  src="https://pics.paypal.com/00/s/YjZiNmEzZTUtMDA2YS00ZjBmLWFkZDQtNjFlOGFhMzE1Njg3/file.PNG"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </Box>
          </Container>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Donations);
