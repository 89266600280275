import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from '../components/Markdown';
import Typography from '../components/Typography';
import AppAppBar from './AppAppBar';
import AppFooter from './AppFooter';
import withRoot from '../withRoot';

const privacy = `
Thank you for choosing our web application for converting financial transaction history from CSV format to TurboTax TXF format. Before using our service, please read and agree to the following terms of use:

Acceptance of Terms of Use: By using our web application, you agree to be bound by these Terms of Use, as well as our Privacy Policy. If you do not agree with these terms, you should not use our service.

User Conduct: You are responsible for your conduct while using our service. You agree not to use our service for any unlawful purpose, to upload any content that is harmful, fraudulent, defamatory, or otherwise objectionable, or to interfere with the functioning of our service.

Accuracy of Information: You are responsible for the accuracy of the financial transaction history data you upload to our service. We do not verify the accuracy of the data, and we are not responsible for any errors or omissions in the data.

Privacy: We take your privacy seriously and will only use your personal information in accordance with our Privacy Policy.

Intellectual Property: All content and materials on our web application, including but not limited to the software, text, graphics, logos, images, and icons, are our property or the property of our licensors, and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, modify, distribute, or display any content or materials without our prior written permission.

Disclaimer of Warranties: Our web application is provided "as is" and without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that our service will be uninterrupted or error-free, or that any errors will be corrected.

Limitation of Liability: We will not be liable for any damages of any kind arising from the use of our web application, including but not limited to direct, indirect, incidental, punitive, and consequential damages. This limitation applies regardless of the legal theory upon which the claim is based.

Indemnification: You agree to indemnify and hold us harmless from any claims, damages, or expenses (including attorneys' fees) arising from your use of our web application, your violation of these Terms of Use, or your violation of any rights of another.

Modification of Terms of Use: We reserve the right to modify these Terms of Use at any time without prior notice. Your continued use of our web application after any such modification constitutes your agreement to the modified terms.

Termination: We reserve the right to terminate your access to our web application at any time for any reason without prior notice.

Governing Law: These Terms of Use are governed by and construed in accordance with the laws of the jurisdiction in which we are located, without giving effect to any principles of conflicts of law.

If you have any questions about these Terms of Use, please contact us.`

function Privacy() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Privacy
          </Typography>
          <Markdown>{privacy}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Privacy);
