import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Button, List, ListItem, ListItemText } from "@mui/material";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function Instructions() {
  return (
    <Box
      component="section"
      id="instructions"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box sx={item}>
              <Typography variant="h6" sx={{ my: 5 }}>
                Instructions
              </Typography>
              <Typography variant="h5">
                {
                  "You will need a CSV export from your financial institution or one that you have created. The file may contain extra fields but, it must contain the following fields:"
                }
              </Typography>
              <Grid container spacing={5} alignItems="center" sx={{mt: 1, mb: 5}}>
                <Grid item sm={4} xs={12}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        primary="Opening Date"
                        secondary="date the position was open"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Opening Net Amount"
                        secondary="The net proceeds or cost to open the position"
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        primary="Closing Date"
                        secondary="date the position was closed"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Closing Net Amount"
                        secondary="The net proceeds or cost to close the position"
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemText
                        primary="Symbol"
                        secondary="symbol the uniquely decribes the asset"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Quantity"
                        secondary="The quantity of the asset"
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Typography variant="h5" component="div">
                You can also optionally use{" "}
                <Box display="inline" fontWeight="fontWeightBold">
                  various
                </Box>{" "}
                for any dates in cases where there are several dates associated
                with the transaction. When doing so you should also add a column
                to distinquish{" "}
                <Box display="inline" fontWeight="fontWeightBold">
                  long term
                </Box>{" "}
                transactions or they will be treated as short term.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              color="secondary"
              variant="contained"
              size="large"
              component="a"
              href="get-started"
              sx={{ minWidth: 200 }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Instructions;
