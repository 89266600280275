import { Box, Button, Container, styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AppFooter from "./AppFooter";
import React from "react";
import AppAppBar from "./AppAppBar";
import withRoot from "../withRoot";

const backgroundImage = "/alexander-grey-8lnbXtxFGZw-unsplash-med.jpg";

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat-y",
  zIndex: -2,
});

function Download() {
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location.state);

  const txf = location.state.txf;

  function downloadFile() {
    // file object
    const file = new Blob([txf as BlobPart], { type: "text/plain" });
    // anchor link
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "easytxf-" + Date.now() + ".txf";
    // simulate link click
    document.body.appendChild(element);
    // Required for this to work in FireFox
    element.click();
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
        <Container
          sx={{ mt: 10, mb: 20, display: "flex", position: "relative" }}
        >
          <Box
            sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
          />
          <Background
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundColor: "#666", // Average color of the background image.
              backgroundPosition: "center",
              opacity: 0.6,
              filter: "brightness(40%)",
              ml: -30,
              mr: -30,
              mt: -10,
              mb: -20,
            }}
          />
          <Container component="main" maxWidth="sm">
            <Box
              sx={{
                backgroundColor: "#dbdbdb",
                border: "1px solid #333",
                padding: 5,
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                textAlign={"center"}
                fontWeight={"bold"}
              >
                TXF File Created
              </Typography>
              <br></br>
              <Typography textAlign={"left"}>
                You can download your file and import it in to TurboTax to
                verify the transactions. Please double-check that everything is
                correct.
              </Typography>
              <br></br>
              <Typography textAlign={"left"}>
                <b>Warning:</b> It is possible that this web service may not
                produce the expected results. Please be aware that the accuracy
                and reliability of the service may be affected by various
                factors such as data quality, system limitations, and technical
                issues. The provider of this web service cannot guarantee the
                accuracy or completeness of the results and shall not be held
                responsible for any damages or losses resulting from the use of
                the service. Users are advised to review the service's terms and
                conditions and privacy policy before using the service.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                component="a"
                fullWidth
                sx={{ mt: 3, mb: 2, minWidth: 200 }}
                onClick={downloadFile}
                key="download"
              >
                Download TXF
              </Button>

              <Typography textAlign={"left"} sx={{ mt: 3 }}>
                If you have found this website to be helpful, I would appreciate
                any donations you might be able to contribute. These donations
                will be used for infrastructure costs, ongoing development, and
                maintenance of the website.
              </Typography>
              <Typography fontWeight={"bold"}>
                Thank you for your support!
              </Typography>

              <Box sx={{ textAlign: "center", width: "100%", mt: 4 }}>
                <form
                  action="https://www.paypal.com/donate"
                  method="post"
                  target="_top"
                >
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="4BNC8E6CFQCFJ"
                  />
                  <input
                    type="image"
                    src="https://pics.paypal.com/00/s/YjZiNmEzZTUtMDA2YS00ZjBmLWFkZDQtNjFlOGFhMzE1Njg3/file.PNG"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Donate with PayPal button"
                  />
                  <img
                    alt=""
                    src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component="a"
                  fullWidth
                  sx={{ mt: 3, mb: 2, minWidth: 200 }}
                  key="upload"
                  onClick={() => navigate("/get-started")}
                >
                  Upload Another File
                </Button>
              </Box>
            </Box>
          </Container>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Download);
