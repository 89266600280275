import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useLocation, useNavigate } from "react-router-dom";
import AppFooter from "./AppFooter";
import React from "react";
import AppAppBar from "./AppAppBar";
import withRoot from "../withRoot";

const DATE_OPEN = "date_open";
const DATE_CLOSE = "date_close";
const LONG_TERM = "term";
const SYMBOL = "symbol";
const OPEN_NET = "open_net";
const CLOSE_NET = "close_net";
const QUANTITY = "quantity";

const DONT_USE = "Don't Use";

function FieldMapping() {
  const [mapping, setMapping] = useState<Map<string, string>>(new Map());
  const [usedAttributes, setUsedAttributes] = useState<Set<string>>(new Set());
  const [message, setMessage] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();

  const columns = location.state.columns;
  const reference = location.state.reference;

  // attributes to be mapped to from the user's file
  const standardAttributes = [
    DATE_OPEN,
    OPEN_NET,
    DATE_CLOSE,
    CLOSE_NET,
    SYMBOL,
    QUANTITY,
    LONG_TERM,
  ];
  const sMap = new Map();
  sMap.set(DATE_OPEN, { name: "Opening Date", required: true });
  sMap.set(DATE_CLOSE, { name: "Closing Date", required: false });
  sMap.set(LONG_TERM, { name: "Term (optional)", required: false });
  sMap.set(SYMBOL, { name: "Symbol", required: true });
  sMap.set(OPEN_NET, { name: "Opening Net Amount $", required: true });
  sMap.set(CLOSE_NET, { name: "Closing Net Amount $", required: true });
  sMap.set(QUANTITY, { name: "Quantity", required: true });

  var initialMapping = new Map();
  var initialAttributes = new Set<string>();
  const getAlternateName = (col: string) => {
    var name = col.split("-", 2)[1];
    var cleanName = name.toLowerCase();
    cleanName = cleanName.replace(/[$()]/g, "").trim(); // remove $ ( ) and trim
    cleanName = cleanName.replace("  ", " ");

    const altMap = new Map<string, string>([
      ["opening date", DATE_OPEN],
      ["start date", DATE_OPEN],
      ["opening", DATE_OPEN],
      ["purchase date", DATE_OPEN],
      ["date", DATE_OPEN],
      ["date acquired", DATE_OPEN],
      ["received date", DATE_OPEN],

      ["closing date", DATE_CLOSE],
      ["end date", DATE_CLOSE],
      ["closing", DATE_CLOSE],
      ["sale date", DATE_CLOSE],
      ["date", DATE_CLOSE],
      ["date sold", DATE_CLOSE],
      ["date of disposition", DATE_CLOSE],

      ["opening net amount", OPEN_NET],
      ["opening net", OPEN_NET],
      ["purchase cost", OPEN_NET],
      ["cost basis", OPEN_NET],
      ["cost", OPEN_NET],
      ["cost basis cb", OPEN_NET],
      ["cost basis usd", OPEN_NET],
      ["cost basis cogs", OPEN_NET],
      ["cogs", OPEN_NET],
      ["total cost", OPEN_NET],
      ["cost or other basis", OPEN_NET],

      ["closing net amount", CLOSE_NET],
      ["closing net", CLOSE_NET],
      ["sale amount", CLOSE_NET],
      ["sale proceeds", CLOSE_NET],
      ["sales proceeds", CLOSE_NET],
      ["proceeds", CLOSE_NET],
      ["proceeds usd", CLOSE_NET],
      ["proceeds investments", CLOSE_NET],

      ["quantity", QUANTITY],
      ["qty", QUANTITY],
      ["number", QUANTITY],
      ["asset amount", QUANTITY],
      ["shares", QUANTITY],

      ["term", LONG_TERM],
      ["long term", LONG_TERM],

      ["symbol", SYMBOL],
      ["security", SYMBOL],
      ["security description", SYMBOL],
      ["currency name", SYMBOL],
      ["asset", SYMBOL],
      ["asset name", SYMBOL],
      ["coin", SYMBOL],
    ]);

    let foundMatch = false;
    altMap.forEach((value, key) => {
      if (cleanName === key && !initialAttributes.has(value)) {
        initialMapping.set(col, value);
        initialAttributes.add(value);
        foundMatch = true;
        return true;
      }
    });
    return foundMatch;
  };

  const setDefaultMapping = () => {
    if (columns) {
      columns.forEach((column: string) => {
        getAlternateName(column);
      });
      setMapping(initialMapping);
      setUsedAttributes(initialAttributes);
    }
  };

  useEffect(() => {
    setDefaultMapping(); // Call the method to set the default fields array
  }, [columns, setMapping, setUsedAttributes]);

  function handleMappingChange(columnName: string, attribute: string) {
    const prevAttribute = mapping.get(columnName);
    if (prevAttribute && prevAttribute !== attribute) {
      setUsedAttributes((usedAttributes) => {
        const newUsedAttributes = new Set(usedAttributes);
        newUsedAttributes.delete(prevAttribute);
        return newUsedAttributes;
      });
    }
    var newMap = new Map(mapping);
    newMap.set(columnName, attribute);

    setMapping(newMap);
    setUsedAttributes(
      (usedAttributes) => new Set([...usedAttributes, attribute])
    );
    setMessage("");
  }

  function isFieldSet(name: string) {
    return Array.from(mapping.values()).includes(name);
  }

  function handleSave() {
    // check required fields are mapped
    for (const [key, value] of sMap) {
      if (value.required && !isFieldSet(key)) {
        setMessage(`${sMap.get(key).name} is required`);
        return;
      }
    }

    let data = {
      reference: reference,
      mapping: Object.fromEntries(mapping),
    };

    // post to API
    ApiService.convert(data)
      .then((response) => {
        console.log(response);
        navigate("/download", {
          state: { txf: response.data },
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          const msg =
            err?.response?.data?.message ??
            "An unexpected error occured converting the file. Please check your field mapping OR file for inconsistent data or formatting.";
          setMessage(msg);
        } else {
          setMessage(
            "Error converting file. Please check your field mapping OR file for inconsistent data or formatting."
          );
        }
      });

    console.log(mapping);
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
        <Container component="main" maxWidth="lg" sx={{ marginTop: "-40px" }}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                textAlign={"center"}
                fontWeight={"bold"}
              >
                Field Mapping
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={6} key="colName" sx={{ mb: 1 }}>
                  <Typography component="h1" variant="h6">
                    Column Name
                  </Typography>
                </Grid>
                <Grid item xs={6} key="TXFField" sx={{ mb: 1 }}>
                  <Typography component="h1" variant="h6">
                    TXF Field
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {columns &&
                    columns.map((column: string, index: string) => (
                      <Grid
                        key={"gridMap" + index}
                        container
                        alignItems="center"
                        direction="row"
                        sx={{ paddingBottom: "10px" }}
                      >
                        <Grid item xs={6} key={"left" + index}>
                          <Typography
                            variant="body1"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                            sx={{ pr: 2 }}
                          >
                            {column.split("-", 2)[1]}
                          </Typography>
                        </Grid>

                        <Grid item xs={6} key={"right" + index}>
                          <FormControl fullWidth key={"right-form" + index}>
                            <Select
                              key={"select" + index + column}
                              value={mapping.get(column) || DONT_USE}
                              onChange={(e) =>
                                handleMappingChange(column, e.target.value)
                              }
                            >
                              {standardAttributes.map((attribute) => (
                                <MenuItem
                                  key={"menu" + index + attribute}
                                  value={attribute}
                                  disabled={usedAttributes.has(attribute)}
                                >
                                  {sMap.get(attribute).name}
                                </MenuItem>
                              ))}
                              <MenuItem
                                key={"menu" + index + "na"}
                                value={DONT_USE}
                              >
                                Don't Use
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>

                <Grid item xs={12} sx={{ paddingBottom: "20px" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    component="a"
                    fullWidth
                    sx={{ mt: 3, mb: 2, minWidth: 200 }}
                    onClick={handleSave}
                    key="save"
                  >
                    Save
                  </Button>
                  <Grid
                    key="gridError"
                    item
                    xs={12}
                    sx={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    {message && (
                      <Alert severity="error" key="alert">
                        {message}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 3, mb: 3 }}>
                <Box sx={{ backgroundColor: "#ddd", p: 3, mt: -3, mb: -3 }}>
                  <Typography component="h1" variant="h6" textAlign={"left"}>
                    Help
                  </Typography>
                  <Typography textAlign={"left"} sx={{ mt: 1 }}>
                    You will need to <b>map the columns</b> from your CSV file
                    to the required fields needed to complete the TXF
                    conversion. You will need to map <b>ALL</b> the required
                    fields before saving.
                  </Typography>
                  <Typography textAlign={"left"} sx={{ mt: 1 }}>
                    You may need to edit your CSV file to include any
                    <b> missing required fields</b>. You may map any extra
                    fields in your CSV file to "Don't Use" to have them ignored.
                    You can use a value of 'various' when a trade has multiple
                    dates.
                  </Typography>
                  <Typography textAlign={"left"} sx={{ mt: 1 }}>
                    If you need to specify whether a trade is long-term or
                    short-term (usually when various dates are used) you can
                    specify that with a Term column. Values of ['long', 'l',
                    'yes', 1, true] will be interpreted as long-term trades. Any
                    other value will signify a short-term trade
                  </Typography>

                  <Divider sx={{ mb: 2, mt: 2 }}></Divider>
                  <Typography textAlign={"left"} sx={{ mt: 1 }}>
                    The following fields are required and need to have a
                    mapping:
                  </Typography>

                  <List dense={true}>
                    <ListItem key="help1">
                      <ListItemText
                        primary="Opening Date"
                        secondary="The date the position was opened."
                        key="helptext1"
                      />
                    </ListItem>
                    <ListItem key="help2">
                      <ListItemText
                        primary="Opening Net Amount"
                        secondary="The net proceeds or cost to open the position. Also referred to as your cost basis. This can be a decimal value with as many decimal places as required. Note that TurboTax may round up to 2 decimal places."
                        key="helptext2"
                      />
                    </ListItem>

                    <ListItem key="help3">
                      <ListItemText
                        primary="Closing Date"
                        secondary="The date the position was closed. The difference between this date and the opening date determine whether the trade is long term or short term."
                        key="helptext3"
                      />
                    </ListItem>
                    <ListItem key="help4">
                      <ListItemText
                        primary="Closing Net Amount"
                        secondary="The net proceeds or cost to close the position. This can be a decimal value with as many decimal places as required. Subtracting your opening net amount from this value would determine your profit (or loss)."
                        key="helptext4"
                      />
                    </ListItem>
                    <ListItem key="help5">
                      <ListItemText
                        primary="Symbol"
                        secondary="A symbol used to uniquely decribes the asset."
                        key="helptext5"
                      />
                    </ListItem>
                    <ListItem key="help6">
                      <ListItemText
                        primary="Quantity"
                        secondary="The quantity of the asset. This can be a decimal value with as many decimal places as required."
                        key="helptext6"
                      />
                    </ListItem>
                  </List>

                  <Typography textAlign={"left"} sx={{ mt: 1 }}>
                    The following fields are optional:
                  </Typography>
                  <List>
                    <ListItem key="help7">
                      <ListItemText
                        primary="Long Term"
                        secondary="You can add column to distinquish long term from short terms trades. This is useful when various if used for dates."
                        key="helptext7"
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(FieldMapping);
